<template>
  <v-tabs
    vertical
    color="success"
    class="px-5 py-4 text-align: center"
    density="compact"
  >
    <!-- Tab Names  -->
    <v-tab>
      Insurance
    </v-tab>
    <v-tab>
      Comments
    </v-tab>
    <v-tab>
      Visit History
    </v-tab>

    <v-tab-item>
      <v-card
        v-if="!patientData.insurance_type_title"
        class="col-sm-12 mb-3"
      >
        <div>
          <h3
            class="col-sm-12"
          >
            No insurance data found!
          </h3>
        </div>
      </v-card>
      <v-card
        v-else
        class="col-sm-12 mb-3"
      >
        <div
          class="col-sm-12"
        >
          <h3
            class="col-sm-12 font-weight-regular"
          >
            Patient Insurance Type: <b>{{ patientData.insurance_type_title }}</b>
          </h3>
          <h3
            class="col-sm-8 font-weight-regular"
          >
            Authorization Obtained
            <v-icon
              class="pl-1"
            >
              {{ patientData.is_auth_obtained ? icon.checkBoxTicked : icon.checkBoxBlank }}
            </v-icon>
          </h3>
          <h3
            v-if="patientData.is_auth_obtained"
            class="col-sm-12 font-weight-regular"
          >
            Authorization Date: <b>{{ $date(patientData.date_auth_obtained).format('MM/DD/YY') }}</b>
          </h3>
        </div>
      </v-card>
    </v-tab-item>

    <!-- Comments  -->
    <v-tab-item
      class="col-sm-12"
    >
      <Comments
        type="insuranceAuth"
        :patient-id="patientData.id"
        :encounter-id="encounter.id"
        :comment-adds="commentAdds"
        :items-per-page="5"
      >
      </Comments>
    </v-tab-item>

        <!-- Patient Note History  -->
    <v-tab-item
      class="col-sm-12"
    >
      <VisitHistory
        :patient="patientData"
        :encounter-id="encounter.id"
        :practice-type="encounter.practice_type_id"
      >
      </VisitHistory>
    </v-tab-item>

    <!-- History  REMOVED, as per Billing-->
    <!-- <v-tab-item eager>
        <v-data-table
          :items="mockDataHistory"
          item-key="id"
          :headers="headersHistory"
          :page.sync="paginationHistory.page"
          :items-per-page="paginationHistory.itemsPerPage"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          hide-default-footer
          class="has-pagination col-sm-12 pa-2"
          @page-count="paginationHistory.pageCount = $event"
        >
        </v-data-table>

        <pagination
          :page.sync="paginationHistory.page"
          :items-per-page.sync="paginationHistory.itemsPerPage"
          :page-count="paginationHistory.pageCount"
          class="col-sm-10"
        ></pagination>
      </v-tab-item>
    </v-tabs> -->
  </v-tabs>
</template>

<script>
import {
  mdiCheckboxBlankOutline, mdiCheckboxOutline,
} from '@mdi/js'
import Comments from '@/components/features/Comments.vue'
import VisitHistory from '@/components/features/VisitHistory.vue'
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'

export default {
  components: { Comments, VisitHistory },
  props: {
    patientData: {
      type: Object,
      default: {},
    },
    encounter: {
      type: Object,
      default: {},
    },
    commentAdds: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icon: {
        checkBoxTicked: mdiCheckboxOutline,
        checkBoxBlank: mdiCheckboxBlankOutline,
      },
    }
  },
  computed: {
    ...mapState('comments', { comments: 'items' }),
    ...mapFields(['online']),
  },
}
</script>
